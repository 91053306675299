import './banner.scss';

import { Slider } from '../slider/slider';

let banner;

$(() => {
    $('.js-slider--banner').map(function() {
        banner = new Slider(this);
    });
});
