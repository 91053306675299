import './single-product.scss';

export class SingleProduct {
    constructor(target) {
        this.element = $(target);

        this.caseToggle       = this.element.find('.form-toggle--labels input');
        this.quantityControl  = this.element.find('.quantity .quantity__input');
        this.cartFormQuantity = this.element.find('.single-product__cart-quantity');
        this.caseSize         = this.caseToggle.closest('.form-toggle--labels').data('pieces');
        this.singleQuantity   = this.quantityControl.val();
        this.caseEnabled      = this.caseToggle.prop('checked');

        this.init();
    }

    init() {
        this.caseToggle.on('change', this.caseToggleListener.bind(this));
        this.quantityControl.on('change', this.quantityListener.bind(this));
    }

    caseToggleListener() {
        let quantity     = this.cartFormQuantity;
        this.caseEnabled = this.caseToggle.prop('checked');

        if (this.caseEnabled) {
            console.log(this.caseSize);
            quantity = this.caseSize * this.singleQuantity;
        } else {
            quantity = this.singleQuantity;
        }

        this.updateQuantity(quantity);
    }

    quantityListener(event) {
        let quantity        = this.cartFormQuantity;
        this.singleQuantity = this.quantityControl.val();

        if (this.caseEnabled) {
            quantity = this.caseSize * this.singleQuantity;
        } else {
            quantity = this.singleQuantity;
        }

        this.updateQuantity(quantity);
    }

    updateQuantity(quantity) {
        this.cartFormQuantity.val(quantity);
    }
}

// Init on document ready
let singleProduct;
$(function () {
    $('.js-single-product').each(function() {
        singleProduct = new SingleProduct(this);
    });
});
