import './product-slider.scss';

import { Slider } from '../slider/slider';

$(() => {
    $('.js-product-slider').map(function() { // Make sure you don't have js-slider class on the element
        this.slider = new Slider(this, {
            slides: {
                perView: 'auto',
                spacing: 16,
            },
            loop: false,
            enableAutoplay: false,
        });
    });
});
