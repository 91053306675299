import './quantity.scss';

export class Quantity {
    constructor(target) {
        this.element = $(target);

        this.plusButton = this.element.find('.quantity__control--plus');
        this.minusButton = this.element.find('.quantity__control--minus');

        this.input = this.element.find('.quantity__input');
        this.value = this.input.val();
        this.min   = this.input.attr('min') ? this.input.attr('min') : 1;
        this.max   = this.input.attr('max') ? this.input.attr('max') : 999;
        this.step  = this.input.attr('step') ? this.input.attr('step') : 1;

        this.init();
    }

    init() {
        this.plusButton.on('click', this.addNum.bind(this));
        this.minusButton.on('click', this.removeNum.bind(this));
    }

    addNum() {
        if (this.max) {
            if (this.input.val() < parseInt(this.max)) {
                this.input.val(parseInt(this.input.val()) + parseInt(this.step));
            }
        } else {
            this.input.val(parseInt(this.input.val()) + parseInt(this.step));
        }

        this.input.trigger('change');
    }

    removeNum() {
        if (this.input.val() > this.min) {
            this.input.val(parseInt(this.input.val()) - parseInt(this.step));
        }

        this.input.trigger('change');
    }

    onChange() {
        this.checkValue();
    }

    checkValue() {
        if (parseInt(this.max) && this.input.val() > parseInt(this.max)) {
            this.input.val(parseInt(this.max));
        }
    }
}

// Init on document ready
let quantity;
$(function () {
    $('.js-quantity').each(function() {
        quantity = new Quantity(this);
    });
});
