const pkg = require('../../../../package.json');

import '../icon/icon';

import './cookie-notice.scss';

import { getCookie, setCookie } from '../helpers/helpers';

export class CookieNotice {
    constructor(target) {
        this.element = $(target);

        this.settings = {
            cookieName: pkg.name + '_cookie_agreement',
        }

        this.classes = {
            openClass: 'is-open',
        }

        this.acceptButton = $('.cookie-notice__accept');
        this.closeButton = $('.cookie-notice__close');

        this.init();
    }

    init() {
        if (getCookie(this.settings.cookieName) === '') {
            this.element.addClass(this.classes.openClass);
        }

        this.acceptButton.on('click', this.acceptCookies.bind(this));
        this.closeButton.on('click', this.closeCookiemodal.bind(this));
    }

    acceptCookies() {
        this.element.removeClass(this.classes.openClass);

        setTimeout(function() {
            this.element.remove();
        }, 301);

        setCookie(this.settings.cookieName, '1', 999);
    }

    closeCookiemodal() {
        this.element.removeClass(this.classes.openClass);
    }
}

// Init on document ready
let cookieNotice;
$(function () {
    $('.js-cookie-notice').each(function() {
        cookieNotice = new CookieNotice(this);
    });
});
