import 'bootstrap/js/dist/modal';
import '../button/button.scss';
import './modal.scss';

export class Modal {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            modal: 'modal',
        };

        this.hash                = window.location.hash;
        this.modalButtonSelector = '[data-bs-target="' + this.hash + '"]';
        this.enquiryApartment    = 'data-name="apartment"';

        this.init();
    }

    init() {
        this.emptyModalFormApartment();

        this.element.on('show.bs.modal', this.onModalOpen.bind(this));
        this.element.on('shown.bs.modal', this.onModalOpened.bind(this));
        this.element.on('hidden.bs.modal', this.onModalHidden.bind(this));
    }

    emptyModalFormApartment() {
        if ($(this.hash).length && $(this.modalButtonSelector).length) {
            $(`[${this.enquiryApartment}]`).val('');
        }
    }

    onModalOpen() {
        $('.tooltip').remove();
    }

    onModalOpened() {
        this.element.addClass('is-shown');
    }

    onModalHidden() {
        this.element.removeClass('is-shown');
    }
}

// Init on document ready
$(() => {
    $('.js-modal').map(function() {
        new Modal(this);
    });
});
