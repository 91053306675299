import { Quantity } from '../quantity/quantity';

import './minicart.scss';

export class MiniCart {
    constructor(target) {
        this.element = $(target);

        this.classes = {
            loadingClass: 'is-loading',
            activeClass: 'is-active',
        }

        this.quantityInput    = this.element.find('.minicart__item-quantity .quantity__control');
        this.removeButton     = this.element.find('.minicart__item-remove .remove');
        this.loader           = this.element.find('.minicart__loader');
        this.cartCounter      = $('.header__cart-bubble');
        this.cartContainer    = this.element.find('.minicart__inner');
        this.couponContainer  = this.element.find('.coupon');
        this.couponButton     = this.element.find('.coupon__button');
        this.couponInput      = this.element.find('.coupon .form__field-input');
        this.cartButtonWrap   = $('.header__cart-item');
        this.quantityTimeout  = 0;

        this.updatingMinicart = false;
        this.addToCartButton  = $('.js-add-to-cart');

        this.addToCartClick   = this.addToCartClick.bind(this);
        this.addToCartSubmit  = this.addToCartSubmit.bind(this);
        this.applyCouponCode  = this.applyCouponCode.bind(this);

        this.init();
    }

    init() {
        this.quantityInput.on('click', this.changeProductQuantity.bind(this));
        this.removeButton.on('click', this.removeCartProduct.bind(this));
        this.couponButton.on('click', this.applyCouponCode);
        this.addToCartButton.on('click', this.addToCartClick);

        $('.single-product__cart-wrap, .variations_form').on('submit', this.addToCartSubmit);
    }

    changeProductQuantity(event) {
        const input = $(event.target).closest('.quantity').find('input');
        const hash  = input.attr('name').replace(/cart\[([\w]+)\]\[qty\]/g, "$1");

        window.clearTimeout(this.quantityTimeout);

        this.quantityTimeout = window.setTimeout(() => {
            this.element.addClass(this.classes.loadingClass);
            const inputValue = parseInt(input.val());
            $.ajax({
                type: 'POST',
                url: estpress.ajaxPath,
                data: {
                    action: 'cart-quantity',
                    hash: hash,
                    quantity: inputValue
                },
                success: (data) => {
                    this.element.removeClass(this.classes.loadingClass);
                    this.updateCart(data.cart, data.cartCount, data.redirect);
                }
            });
        }, 1200);
    }

    removeCartProduct(event) {
        event.preventDefault();

        const href = $(event.target).attr('href');
        this.element.addClass(this.classes.loadingClass);

        $.ajax({
            type: 'POST',
            url: estpress.ajaxPath,
            data: {
                action: 'cart-remove-product',
                href: href
            },
            success: (data) => {
                this.element.removeClass(this.classes.loadingClass);
                this.updateCart(data.cart, data.cartCount, data.redirect);
            }
        });
    }

    addToCartClick(event) {
        event.preventDefault();
        const productId = $(event.currentTarget).data('id');

        if (productId) {
            this.element.addClass(this.classes.loadingClass);
            this.cartButtonWrap.addClass(this.classes.activeClass);

            if (!this.updatingMinicart) {
                this.updatingMinicart = true;

                $.ajax({
                    type: 'POST',
                    url: estpress.ajaxPath,
                    data: {
                        action: 'add-to-cart',
                        id: productId,
                    },
                    success: (data) => {
                        this.updatingMinicart = false;
                        this.element.removeClass(this.classes.loadingClass);
                        this.updateCart(data.cart, data.cartCount);
                    }
                });
            }
        }
    }

    addToCartSubmit(event) {
        event.preventDefault();

        const currentForm = $(event.currentTarget);

        this.element.addClass(this.classes.loadingClass);
        this.cartButtonWrap.addClass(this.classes.activeClass);

        if (!this.updatingMinicart) {
            let formData = currentForm.serializeArray();
            // object serialize dash fix
            formData['add-to-cart'] = currentForm.find('[name="add-to-cart"]').val();
            this.updatingMinicart   = true;

            $.ajax({
                type: 'POST',
                url: window.location.href,
                data: formData,
                success: (data) => {
                    this.updatingMinicart = false;
                    this.element.removeClass(this.classes.loadingClass);
                    this.updateCart(data.cart, data.cartCount);
                }
            });
        }
    }

    applyCouponCode(event) {
        event.preventDefault();

        const code = this.couponInput.val();
        this.element.addClass(this.classes.loadingClass);

        $.ajax({
            type: 'POST',
            url: estpress.ajaxPath,
            data: {
                action: 'cart-apply-coupon',
                coupon_code: code
            },
            success: (data) => {
                this.element.removeClass(this.classes.loadingClass);
                $('body').trigger('update_checkout');
                $(document).trigger('updated_cart_totals');

                this.couponContainer.find('.coupon__error-message').remove();
                this.couponContainer.prepend('<span class="coupon__error-message">' + data.couponMessage + '</span>');
            }
        });
    }

    updateCart(cartMarkup, itemsCount, redirect = false) {
        this.cartCounter.html(itemsCount);
        const newMinicart = $(cartMarkup);
        this.element.replaceWith(newMinicart);

        $('body').trigger('update_checkout');
        $(document).trigger('updated_cart_totals');

        this.cleanupListeners();

        // Reinit minicart and quantity component
        let cart = new MiniCart(newMinicart[0]);
        let quantityInputs;
        newMinicart.find('.js-quantity').each(function() {
            quantityInputs = new Quantity(this);
        });
    }

    cleanupListeners() {
        $('.single-product__cart-wrap, .variations_form').off('submit', this.addToCartSubmit);
        this.addToCartButton.off('click', this.addToCartClick);
        this.couponButton.off('click', this.applyCouponCode);
    }
}

// Init on document ready
let minicart;
$(function () {
    $('.js-minicart').each(function() {
        minicart = new MiniCart(this);
    });
});
