import './collapse.scss';

import 'bootstrap/js/dist/collapse';

// Method examples
// $('#collapse-1').collapse('show');

// Event examples
// $('.collapse').on('show.bs.collapse', (event) => {
//     const target = $(event.currentTarget);
// });
