import "./map.scss";
import { Loader } from 'google-maps';
import createHTMLMapMarker from '../map-marker-html/map-marker-html';

export class BeekeeperMap {
    constructor(target) {
        this.classes = {
            activeClass: 'is-active',
        };

        this.element      = $(target);
        this.body         = $(document.body);
        this.mapContainer = target;
        this.locations    = {};
        this.markers      = new Array();
        this.infoWindow   = {};
        this.marker       = {};
        this.map          = {};
        this.type         = this.element.hasClass('map--small') ? 'small' : 'large';

        this.init();
    }

    init() {
        if (!window.google || !window.google.maps) {
            this.loader       = new Loader('AIzaSyA7yzHWruapmiTpGdBi3vUbKu_nQbE4dD0');
            this.loader.load().then(() => {
                if (this.mapContainer) {
                    this.initGoogleMaps();
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            if (this.mapContainer) {
                this.initGoogleMaps();
            }
        }
    }

    initGoogleMaps() {
        this.map = new google.maps.Map(this.mapContainer, {
            center: {
                lat: -34.397,
                lng: 150.644
            },
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "saturation": "0"
                        },
                        {
                            "lightness": "0"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "saturation": "17"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "hue": "#91ff00"
                        },
                        {
                            "lightness": "56"
                        },
                        {
                            "saturation": "26"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5d2c4"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#f5d2c4"
                        },
                        {
                            "lightness": "60"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f3f3f3"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#e9f6f8"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ],
        });

        // this.infoWindow = new google.maps.InfoWindow();
        const infoWindow = new google.maps.InfoWindow();
        this.locations = JSON.parse(this.mapContainer.getAttribute('data-markers'));

        for (let i = 0; i < this.locations.length; i++) {
            const url         = this.locations[i][3];
            const text        = this.locations[i][0];
            const lat         = this.locations[i][1];
            const lng         = this.locations[i][2];
            const bgColor     = this.locations[i][4];
            const isLightDark = this.locations[i][5];
            const bgStyle     = bgColor ? ` style="--marker-background-color: ${bgColor};"` : '';
            const icon        = estpress.icons.markerBeehiveSVG;
            const title       = text ? `data-title="${text}"` : '';

            const marker = createHTMLMapMarker({
                latlng: new google.maps.LatLng(lat, lng),
                map: this.map,
                html: `<div class="map-marker-html${isLightDark ? ` ${isLightDark}` : ``}"${bgStyle}${title ? ` ${title}` : ``}>${icon}</div>`,
            });

            google.maps.event.addListener(marker, 'click', () => {
                if (url.length > 0) {
                    window.location.href = url;
                }
            });

            this.markers.push(marker);
        }

        this.autoCenterMap();
    }

    autoCenterMap() {
        let bounds = new google.maps.LatLngBounds();

        $.each(this.markers, function (index, marker) {
            bounds.extend(marker.latlng);
        });

        this.map.fitBounds(bounds);

        google.maps.event.addListenerOnce(this.map, 'bounds_changed', (event) => {
            if (this.type == 'small') {
                this.map.setZoom(7);
            } else {
                this.map.setZoom(8);
            }
        });
    }
}

// Init on document ready
$(() => {
    if ($('body').hasClass('wp-admin')) {
        let googleMap;
        let mapsArray = [];
        setInterval(() => {
            const elements = document.querySelectorAll('.wp-block .js-map');
            Array.prototype.forEach.call(elements, (el, i) => {
                if (!mapsArray[i]) {
                    googleMap = new BeekeeperMap(el);
                    mapsArray.push(googleMap);
                }
            });
        }, 1000);
    } else {
        let googleMap;
        $('.js-map').each(function() {
            googleMap = new BeekeeperMap(this);
        });
    }
});
