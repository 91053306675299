import 'swiper/swiper-bundle.css';
import './slider.scss';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export class Slider {
    constructor(target) {
        this.element = $(target);
        this.slideCount = this.element[0].querySelectorAll('.swiper-slide').length;

        this.init();
    }

    init() {
        if (this.element.hasClass('js-slider--logobar')) {
            var options = {
                loop: true,
                autoplay: {
                    delay: 1,
                    disableOnInteraction: false
                },
                freeMode: true,
                speed: 3000,
                slidesPerView: 6,
                simulateTouch: false,
                breakpoints: {
                    // when window width is >= 768
                    768: {
                        spaceBetween: 56,
                        speed: 8000,
                    },
                    200: {
                        spaceBetween: 24,
                    }
                },
                // on: {
                //     init: () => {},
                //     resize: () => {},
                // }
            };
        }
        else if (this.element.hasClass('js-slider--banner')) {
            var options = {
                autoplay: {
                    delay: 4500,
                },
                freeMode: false,
                speed: 900,
                loop: true,
                enableAutoplay: true,
                slidesPerView: 1,
                effect: 'slide',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            };
        } else {
            var options = {
                loop: false,
                effect: 'fade', // "slide", "fade", "cube", "coverflow" or "flip"
                speed: 900,
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                simulateTouch: false,
                on: {
                    slideChange: (swiper) => {
                        console.log(swiper);
                    },
                }
            };
        }

        // var slideCount = document.querySelectorAll('.swiper-container .swiper-slide').length;
        if (this.slideCount > 1) {
            const swiper = new SwiperCore(this.element[0], options);
        }
    }

}

// Init on document ready
let slider;
$(function () {
    $('.js-slider').each(function() {
        setTimeout(() => {
            slider = new Slider(this);
        }, 200);
    });
});
