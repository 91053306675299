import './gravityforms.scss';

$(document.body).on('submit', '.form--gravity', (event) => {
    const target = $(event.currentTarget);
    const button = target.find('.gform_footer .button');

    target.addClass('is-loading');

    if (!button.parent().find('.loader').length) {
        $('<div class="loader"></div>').insertAfter(button);
    }
});
