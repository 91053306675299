import { Carousel, Fancybox } from '@fancyapps/ui/';
import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js';

import './gallery.scss';

export class Gallery {
    constructor(target, settings = {}, carouselSettings = {}) {
        this.element = $(target);

        this.settings         = {...settings};
        this.settings         = {
            ...this.settings,
            on: {
                // '*': this.onAnyEvent.bind(this),
                // 'init': this.onInit.bind(this),
            },
        };
        this.carouselSettings = {...carouselSettings};

        // this.settings = {
        //     Carousel: {
        //         infinite: false,
        //     },
        //     ...settings,
        // };

        // this.carouselSettings = {
        //     infinite: false,
        //     Dots: false,
        //     Thumbs: { // https://fancyapps.com/fancybox/plugins/thumbs/
        //         type: 'classic',
        //         Carousel: {
        //             slidesPerPage: 1,
        //             Navigation: true,
        //             center: true,
        //             fill: true,
        //             dragFree: true,
        //             breakpoints: {
        //                 '(min-width: 640px)': {
        //                     axis: 'y',
        //                 },
        //             },
        //         },
        //     },
        //     ...carouselSettings,
        // };

        this.init();
    }

    init() {
        if (Object.keys(this.carouselSettings).length) {
            new Carousel(
                this.element.get(0),
                this.carouselSettings,
                { Thumbs },
            );
        }

        Fancybox.bind(this.element.get(0), '[data-fancybox]', this.settings);
    }

    // onAnyEvent(fancybox, eventName) {
    // console.log(`Fancybox eventName: ${eventName}`);
    // }

    // onInit(fancybox, eventName) {
    //     console.log(`Fancybox eventName: ${eventName}`);
    // }
}

// Init on document ready
$(() => {
    $('.js-gallery').map(function() {
        new Gallery(this);
    });
});

/**
 * Extending current Gallery class example:
 *
 * import { Gallery } from '../gallery/gallery';
 *
 * export const ProductGalleryThumbs = {
 *    Dots: false,
 *    Thumbs: {
 *        type: 'classic',
 *        Carousel: {
 *            slidesPerPage: 1,
 *        },
 *    },
 * };
 *
 * $(() => {
 *    $('.js-product-gallery').map(function() { // Make sure you don't have js-gallery class on the element
 *        new Gallery(
 *            this,
 *            ProductGalleryThumbs,
 *            ProductGalleryThumbs,
 *        );
 *    });
 * });
 */
