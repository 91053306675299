const pkg = require('../../../../package.json');

import './tax-toggle.scss';

import { getCookie, setCookie } from '../helpers/helpers';

export class TaxToggle {
    constructor(target) {
        this.element = $(target);

        this.settings = {
            cookieName: pkg.name + '_tax_excluded',
        }

        this.toggleInput = this.element.find('.form__field-input');

        this.init();
    }

    init() {
        if (getCookie(this.settings.cookieName) === 'true') {
            this.toggleInput.prop('checked', false);
            $('body').addClass('is-tax-excluded');
        }

        this.toggleInput.on('change', this.toggleChange.bind(this));
    }

    toggleChange() {
        if(this.toggleInput[0].checked) {
            setCookie(this.settings.cookieName, 'false', 999);
            $('body').removeClass('is-tax-excluded');
        } else {
            setCookie(this.settings.cookieName, 'true', 999);
            $('body').addClass('is-tax-excluded');
        }
    }
}

// Init on document ready
let taxToggle;
$(function () {
    $('.js-tax-toggle').each(function() {
        taxToggle = new TaxToggle(this);
    });
});
