import "./card.scss";
export class Card {
    constructor(target) {
        // this.settings = {
        //     transitionDuration: 250,
        // };
        this.classes = {
            activeClass: 'is-active',
            navSelector: 'list-categories',
            postsSelector: 'card-grid',
            paginationSelector: 'pagination',
        };

        this.element        = $(target);
        this.body           = $(document.body);
        this.ajaxLoadTarget = $('.' + this.classes.navSelector + ' a, ' + '.' + this.classes.paginationSelector + ' a');

        this.init();
    }

    init() {
        this.ajaxLoadTarget.on('click', this.ajaxLoadPosts.bind(this));
    }

    ajaxLoadPosts(event) {
        event.preventDefault();

        const that     = this;
        const $element = $(event.currentTarget);
        const url      = $element.attr('href');

        if ($element.closest('.' + this.classes.navSelector).hasClass(this.classes.navSelector)) {
            $element.closest('.' + this.classes.navSelector).find('.button').removeClass(this.classes.activeClass);
            $element.addClass(this.classes.activeClass);
        }

        $.ajax({
            url: url,
            type: 'GET',
            success: function(data) {
                var $collection = $('<div>').append(data).find('.' + that.classes.postsSelector);
                var $pagination = $('<div>').append(data).find('.' + that.classes.paginationSelector);

                $('.' + that.classes.postsSelector).replaceWith($collection);
                $('.' + that.classes.paginationSelector).replaceWith($pagination);

                if (! $('.' + that.classes.paginationSelector).length && $pagination.length) {
                    $($pagination).insertAfter($('.' + that.classes.postsSelector));
                }

                history.pushState(null, null, url);
            }
        });
    }
}

// Init on document ready
let card;
$(function () {
    $('.js-card-grid').each(function() {
        card = new Card(this);
    });
});
