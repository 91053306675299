import 'swiper/swiper-bundle.css';
import './products-grid.scss';

import SwiperCore, { Navigation, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, EffectFade]);

export class ProductsGrid {
    constructor(target) {
        this.element = $(target).find('.products-grid__slider');

        this.init();
    }

    init() {
        const options = {
            loop: false,
            speed: 900,
            slidesPerView: 1,
            spaceBetween: 4,
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                }
            },
            navigation: {
              nextEl: '.products-grid__nav-button--right',
              prevEl: '.products-grid__nav-button--left',
            },
            simulateTouch: false,
        };

        if($('body').hasClass('theme-sireli') || $('body').hasClass('theme-muhemesi')) {
            options.spaceBetween = 24;
        }

        const swiper = new SwiperCore(this.element[0], options);
    }
}

// Init on document ready
let productsGrid;
jQuery(function ($) {
    $('.js-products-grid').each(function() {
        productsGrid = new ProductsGrid(this);
    });
});
