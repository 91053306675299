import { Gallery } from '../gallery/gallery';

import './product-single.scss';

export const ProductGalleryThumbs = {
    Dots: false,
    Navigation: true,
    Thumbs: {
        type: 'classic',
        Carousel: {
            slidesPerPage: 1,
            Navigation: false,
            center: false,
        },
    },
};

$(() => {
    $('.js-product-gallery').map(function() { // Make sure you don't have js-slider class on the element
        new Gallery(
            this,
            ProductGalleryThumbs,
            ProductGalleryThumbs,
        );
    });
});
