import Tooltip from 'bootstrap/js/dist/tooltip';
import './tooltip.scss';

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl, {
        boundary: 'window',
        container: 'body',
        trigger: 'focus',
        offset: [0, 10],
    });
});
