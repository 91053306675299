import './modal-newsletter.scss';

import { getCookie, setCookie } from '../helpers/helpers';

export class ModalNesletter {
    constructor(target) {
        this.element    = $(target);
        this.openedOnce = false;

        this.init();
    }

    init() {
        this.openModal();

        this.element.on('show.bs.modal', this.onModalOpen.bind(this));
    }

    openModal() {
        if (this.openedOnce) {
            return false;
        }

        if (getCookie('modal_banner')) {
            return false;
        }

        setTimeout(() => {
            this.element.modal('show');
            setCookie('modal_banner', true, 1);
        }, 5000);
    }

    onModalOpen() {
        this.openedOnce = true;
    }
}

// Init on document ready
window.estpress.modalBanner = [];
$(() => {
    $('.js-modal-newsletter').map(function() {
        window.estpress.modalBanner.push(new ModalNesletter(this));
    });
});
