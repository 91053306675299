import "./helpers.scss";

export function disableScroll() {
    if (!$('body').hasClass('is-scroll-disabled')) {
        const curScroll = $(window).scrollTop();

        $('body').addClass('is-scroll-disabled');
        $('.wrapper').css('top', -curScroll);

        $(window).scrollTop(0);
    }
}

export function enableScroll() {
    const bodyScroll = parseInt($('.wrapper').css('top'), 10);

    $('body').removeClass('is-scroll-disabled');
    if (bodyScroll) {
        $(window).scrollTop(-bodyScroll);
        $('.wrapper').css('top', 0);
    }
}

export function postAjax(url, data, success) {
    var params = typeof data == 'string' ? data : Object.keys(data).map(
        function(k) { return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) }
    ).join('&')

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')
    xhr.open('POST', url)
    xhr.onreadystatechange = function() {
        if (xhr.readyState > 3 && xhr.status === 200) {
            success(xhr.responseText)
        }
    }

    var isIE11 = !! window.MSInputMethodContext && !! document.documentMode

    if (! isIE11) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    }

    xhr.send(params)
    return xhr
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();

    d.setTime(d.getTime() + (exdays*24*60*60*1000));

    const expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
    const name          = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca            = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

if (typeof $ === 'undefined') {
    $ = jQuery;
}

/**
 * Document ready
 */
$(() => {
    let loadTimer = 0;
    // Calc correct app height for mobile devices
    document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');
    $(window).on('resize', () => {
        document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px');

        // Remove transitions when resizing
        if (loadTimer) {
            clearTimeout(loadTimer);
            loadTimer = null;
        } else {
            $('body').addClass('preload');
        }

        loadTimer = setTimeout(() => {
            $('body').removeClass('preload');
            loadTimer = null;
        }, 100);
    });

    $(window).on('resize load', () => {
        if($('body').hasClass('is-user-logged-in')) {
            const adminHeight = $('#wpadminbar').outerHeight();
            $('.header').css('top', adminHeight + 'px');
        }
    });

    $('.single-product__cart input[name="variation_id"]').on('change', (event) => {
        setTimeout(() => {
            const variationId = $(event.target).val();
            const initialValue = $(event.target).data('initialvalue');
            if(!(variationId.length > 0)) {
                $(event.target).val(initialValue);
            }
        }, 50);
    });
});

/**
 * Onload
 */
 $(window).on('load', () => {
    $('body').removeClass('preload');
    $('body').addClass('is-loaded');
});

