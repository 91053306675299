import './hero.scss';

export class Hero {
    constructor(target) {

        this.classes = {
            heroContentClass: 'hero__content',
            videoClass: 'hero__video',
            videoPlayClass: 'hero__button-play',
            videoFileClass: 'hero__video-file',
            visibleClass: 'is-visible',
        };

        this.element          = $(target);
        this.body             = $('body');
        this.video            = $('.' + this.classes.videoClass);
        this.videoPlayButton  = $('.' + this.classes.videoPlayClass);
        this.videoFile        = $('.' + this.classes.videoFileClass);
        this.heroVideoContent = $('.' + this.classes.heroContentClass);

        this.init();
    }

    init() {
        this.videoPlayButton.on('click', this.playVideo.bind(this));
    }

    playVideo(event) {
        event.preventDefault();
        this.videoFile.get(0).play();
        this.video.addClass('is-visible');
        this.heroVideoContent.addClass('is-hidden');
    }
}

// Init on document ready
let hero;
$(function () {
    $('.js-hero').each(function() {
        hero = new Hero(this);
    });
});
