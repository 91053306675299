import 'bootstrap/js/dist/collapse';
import stickybits from 'stickybits';
import { Quantity } from '../quantity/quantity';
import './cart.scss';
export class Cart {
    constructor(target) {
        this.element = $(target);

        this.init();
    }

    init() {
        if(this.element.hasClass('js-sticky')) {
            stickybits(this.element.find('.checkout__cart-inner'), {
                stickyBitStickyOffset: 98,
                useStickyClasses: true
            });
        }
    }
}

// Init on document ready
let cart;
$(function () {
    $('.js-cart').each(function() {
        cart = new Cart(this);
    });
});
